import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/HomePage'),
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import('@/views/AboutSystemMenus'),
  },
  {
    path: '/using-system/:menu',
    name: 'usingSystem',
    component: () => import('@/views/UsingSystem'),
  },
  {
    path: '/all-news',
    name: 'AllNews',
    component: () => import('@/views/AllNews'),
  },
  {
    path: '/news-more/:id',
    name: 'NewsMore',
    component: () => import('@/views/NewsMoreView'),
  },
  {
    path: '/reports-filed/:menu/:type',
    name: 'ReportsByFiled',
    component: () => import('@/views/ReportsByFiled'),
  },
  {
    path: '/faqs',
    name: 'Faqs',
    component: () => import('@/views/Faqs'),
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/SearchView'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
