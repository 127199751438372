import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const theme = localStorage.getItem('theme');

export default new Vuex.Store({
  state: {
    theme,
  },
  mutations: {
    setTheme(state, payload) {
      state.theme = payload;
    },
  },
  actions: {
    changeTheme({ commit }, value) {
      commit('setTheme', value);
    },
  },
  getters: {
    theme(state) {
      return state.theme;
    },
  },
});
