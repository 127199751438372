<template>
  <v-app>
    <v-main :class="theme">
      <router-view />
    </v-main>
    <v-dialog :class="theme" v-model="dialog" transition="dialog-top-transition" max-width="700">
      <template v-slot:activator="{ on, attrs }">
        <button v-bind="attrs" v-on="on" :class="['message-btn', theme]">
          <span class="icon icon-iconMessage"></span>
        </button>
      </template>
      <div class="message-dialog">
        <div class="message-header">
          <h5>{{ $t('feedback') }}</h5>
          <span @click="dialog = false" class="icon icon-close"></span>
        </div>
        <v-form @submit.prevent="newfeedback" class="message-form">
          <label>
            {{$t('name')}}
            <input
              type="text"
              v-model="userName"
              :placeholder="$t('enterText')"
              :class="[errorClassUserName, 'FeedbackuserName']"
              @input="checksInputValue('errorClassUserName', $event)"
            />
          </label>
          <label>
            {{ $t('title') }}
            <input
              type="text"
              v-model="feedbackTitle"
              :placeholder="$t('enterText')"
              @input="checksInputValue('errorClassfeedbackTitle', $event)"
              :class="errorClassfeedbackTitle"
            />
          </label>
          <label>
            {{ $t('message') }}
            <textarea
              v-model="feedbackMessage"
              :placeholder="$t('enterMessage')"
              :class="errorClassfeedbackMessages"
              @input="checksInputValue('errorClassfeedbackMessages', $event)"
            >
            </textarea>
          </label>
          <button :class="theme" type="summbit">{{ $t('send') }}</button>
        </v-form>
      </div>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbartext }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="success"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Api from '@/api/Api';

export default {
  name: 'App',
  data() {
    return {
      speechButton: null,
      dialog: false,
      errorClassUserName: '',
      errorClassfeedbackTitle: '',
      errorClassfeedbackMessages: '',
      userName: '',
      feedbackTitle: '',
      feedbackMessage: '',
      snackbar: false,
      snackbartext: 'Xabar muvaffaqiyatli jonatildi',
    };
  },
  methods: {
    checksInputValue(inputClass, event) {
      if (event.target.value.length > 5) {
        this[inputClass] = '';
      }
    },
    async newfeedback() {
      if (
        this.userName.length < 2
        || this.feedbackTitle.length < 2
        || this.feedbackMessage.length < 2
      ) {
        if (this.feedbackMessage.length < 2) {
          this.errorClassfeedbackMessages = 'error-input';
          this.snackbar = true;
          this.snackbartext = 'Xabarnoma 20 ta belgidan kam bolmasligi kerak';
        } else {
          this.errorClassfeedbackMessages = '';
        }
        if (this.feedbackTitle.length < 2) {
          this.errorClassfeedbackTitle = 'error-input';
        } else {
          this.errorClassfeedbackTitle = '';
        }
        if (this.userName.length < 2) {
          this.errorClassUserName = 'error-input';
        } else {
          this.errorClassUserName = '';
        }
      } else {
        const body = {
          name: this.userName,
          title: this.feedbackTitle,
          message: this.feedbackMessage,
        };
        await Api.newfeedback(body);
        this.snackbar = true;
        this.snackbartext = this.$t('MessageWasSentSuccessfully');
        this.dialog = false;
        this.userName = '';
        this.feedbackTitle = '';
        this.feedbackMessage = '';
      }
    },
  },
  computed: {
    ...mapGetters(['theme']),
  },
  mounted() {
    if (localStorage.getItem('language') === null) {
      localStorage.setItem('language', 'uz');
    }
    window.addEventListener('keyup', (event) => {
      if (event.ctrlKey && event.keyCode === 13) {
        if (window.getSelection().toString() !== '') {
          const error = window.getSelection().toString();
          this.feedbackMessage = error;
          this.feedbackTitle = this.$t('anErrorWasFoundOnSite');
          this.dialog = true;
        }
      }
    });
  },
};
</script>

<style lang="scss">
@import "@/scss/styles";
.theme-a {
  filter: grayscale(100%);
}

.theme-a1 {
  filter: grayscale(100%) invert(100%);
  -webkit-filter: grayscale(100%) invert(100%);
}

html {
  font-size: 16px;
  background: #FAFBFC;
}
.message {
  &-btn {
    width: 60px;
    height: 60px;
    background-color: #2677b1;
    position: fixed;
    right: 52px;
    bottom: 52px;
    border-radius: 50%;
    z-index: 100;
  }

  &-header {
    h5 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: 33px;
      letter-spacing: 0em;
      text-align: center;
    }
  }

  &-form {
    width: 100%;
    display: flex;
    flex-direction: column;

    label {
      font-size: 1rem;
      font-weight: 700;
      line-height: 26px;
      text-align: left;
      color: #566683;
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0;
      }

      input {
        width: 100%;
        height: 52px;
        border: 1px solid #d6dce8;
        border-radius: 6px;
        color: #21252e;
        font-weight: 400;
        font-size: 1rem;
        padding: 15px 24px 13px;
        margin-top: 14px;
        &:focus {
          outline: none;
        }
      }

      textarea {
        width: 100%;
        height: 138px;
        border: 1px solid #d6dce8;
        border-radius: 6px;
        resize: none;
        padding: 15px 24px;
        color: #21252e;
        font-weight: 400;
        &:focus {
          outline: none;
        }
      }
    }

    button {
      width: 192px;
      height: 52px;
      border-radius: 8px;
      outline: none;
      background-color: #0071BC;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 1rem;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      margin: 0 auto;
    }
  }

  &-dialog {
    z-index: 100000;
    padding: 50px 90px 55px;
    background-color: #ffffff;
    box-shadow: 0px 2px 8px rgba(157, 165, 185, 0.3);
    border-radius: 20px;
    position: relative;
    .icon-close {
      position: absolute;
      top: 35px;
      right: 35px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.error-input {
  border-color: red !important;
}
</style>
