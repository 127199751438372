import axios from 'axios';
import { baseUrl } from '@/config/';

axios.defaults.baseURL = baseUrl;
const curLang = localStorage.getItem('language');

axios.interceptors.request.use((config) => {
  axios.defaults.headers['Accept-Language'] = curLang;
  return config;
});

axios.defaults.headers['Accept-Language'] = curLang;

axios.interceptors.response.use(
  (response) => response,
  async (error) => Promise.reject(error),
);

export default {
  async request({
    url, data, params, method,
  }) {
    try {
      const body = {
        method,
        url,
        data,
        params,
      };
      // eslint-disable-next-line consistent-return
      const response = await axios(body).catch(async (error) => {
        if (error) {
          return error;
        }
      });
      return response;
    } catch (e) {
      this.emitMessage({
        detail: e.toString(),
        type: 'error',
      });
      return {
        error: {
          detail: e.toString(),
        },
      };
    }
  },
  get(url, params) {
    return this.request({
      method: 'GET',
      url,
      params,
    });
  },
  post(url, data) {
    const response = this.request({
      method: 'post',
      url,
      data,
    });

    return response;
  },
  getNewsData(params) {
    return this.get('/announcements/', params);
  },
  getUsefulLinksData(params) {
    return this.get('/common/links/', params);
  },
  getFaqData(params) {
    return this.get('/common/faqs/', params);
  },
  getNewData(id, params) {
    return this.get(`/announcements/${id}/`, params);
  },
  newfeedback(params) {
    return this.post('/common/feedback/', params);
  },
  getOpenInformationData(params) {
    return this.get('/sdmx/', params);
  },
  getOpenInformationDataWeb(params) {
    return this.get('/sdmx/json/', params);
  },
  getOpenInformationChildren(id, params) {
    return this.get(`/sdmx/${id}/children/`, params);
  },
  getOneOpenIformation(id, params) {
    return this.get(`/sdmx/${id}/`, params);
  },
  getMetaData(id, params) {
    return this.get(`/sdmx/${id}/metadata/`, params);
  },

  getRaytingData(id, params) {
    return this.get(`/sdmx/${id}/rating/`, params);
  },

  getTableData(id, params) {
    return this.get(`/sdmx/${id}/table/`, params);
  },

  getGistogramData(id, params) {
    return this.get(`/sdmx/${id}/gistogram/`, params);
  },

  getYearsData(id, params) {
    return this.get(`/sdmx/${id}/years/`, params);
  },

  getAreaChartData(id, params) {
    return this.get(`/sdmx/${id}/graphics/`, params);
  },

  downloadTableFile(id, params) {
    return this.get(`sdmx/${id}/table/download/`, params);
  },
  getThirdLevelItems(params) {
    return this.get('/sdmx/last-layer/', params);
  },
  getRegionsList(id) {
    return this.get(`/sdmx/${id}/regions/`);
  },
  getRelationshipsTableData(id, params) {
    return this.get(`/sdmx/${id}/comparative-ratio/`, params);
  },
  getCastomData(id, params) {
    return this.get(`/sdmx/${id}/customization/`, params);
  },
  getRegionData(id, params) {
    return this.get(`/sdmx/${id}/regions/`, params);
  },
  getCartogramData(id, params) {
    return this.get(`/sdmx/${id}/cartogram/`, params);
  },
  getMapCompareData(id, params) {
    return this.get(`/sdmx/${id}/compare-regions/`, params);
  },
  getActiveUsers(params) {
    return this.get('/common/online-users/', params);
  },
  getCounters(params) {
    return this.get('/common/counters/', params);
  },
  getGistogramRegionsData(id, classificator, params) {
    return this.get(`/sdmx/${id}/gistogram/${classificator}/`, params);
  },
};
